<template>
  <div v-if="item" class="item grid gtc-1-auto ai-fs p-20 pos-r">
    <!-- Delete confirmation -->
    <div v-if="isDelete" class="item-delete pos-a z2 flex fd-c ai-c jc-c ggap-10">
      <div v-if="isLoad" class="flex fd-c ai-c ggap-5">
        <BaseLoad class="rel sm" />
        <h3 class="title t-white">Идет удаление записи!</h3>
      </div>
      <template v-else>
        <h3 class="title t-white ta-center">Вы действительно хотите удалить мероприятие?</h3>
        <div class="flex ai-c ggap-10">
          <button class="btn primary sm" @click="deleteAction(true)">Удалить навсегда</button>
          <button v-if="item.status === 'active'" class="btn grey sm" @click="deleteAction(false)">В архив</button>
          <button class="btn cancel sm" @click="isDelete = false">Отмена</button>
        </div>
      </template>
    </div>

    <!-- Data -->
    <div class="grid ai-c h-100 pr-20 ggap-5">
      <!-- Title -->
      <RouterLink :to="'/events/single/' + item.id" class="item-title title cut fs-18">
        {{ item.title }}
      </RouterLink>

      <!-- Date & Status -->
      <div class="flex ai-c ggap-10">
        <span>{{ formatDateTime(item.date_event) }}</span>
        <!-- <template v-if="getDayCount(item.date_event)">
          <span class="dot"></span>
          <span>До начала: {{ getDayCount(item.date_event) }}</span>
        </template> -->
        <small
          v-if="item.status && item.status !== 'active'"
          class="marker"
          :class="{ green: item.status === 'finished', red: item.status === 'deleted' }"
        >
          {{ item.status === 'finished' ? 'Завершен' : 'Удален' }}
        </small>
      </div>

      <!-- Links -->
      <div class="item-sub cut mt-10">
        <template v-if="item.event_location">
          <RouterLink :to="`/meeting-rooms/single/${item.event_location.id}`" class="iflex ai-c ggap-5 item-count">
            <BaseIcon class="ic-14 grey" icon="location" /> {{ item.event_location.name }}
          </RouterLink>
          <span class="dot"></span>
        </template>
        <template v-if="item.meeting_link">
          <a :href="item.meeting_link" class="iflex ai-c ggap-5 item-count" target="__blank">
            <BaseIcon class="ic-14 grey" icon="link" /> Ссылка на встречу
          </a>
          <span class="dot"></span>
        </template>
        <span class="iflex ai-c ggap-5 item-count">
          <BaseIcon class="ic-14 grey" icon="comment-circle" /> Вопросов:
          {{ item.event_questions ? item.event_questions.length : '0' }}
        </span>
      </div>
    </div>

    <!-- Menu -->
    <BaseDotMenu v-if="!isDelete">
      <button class="btn transparent-grey" @click.prevent="shareAction">
        <BaseIcon class="ic-16 grey" icon="share" /> Поделиться
      </button>

      <template v-if="isAdmin">
        <hr />
        <button
          v-if="item.status === 'active'"
          class="btn transparent-grey"
          @click="$busEmit('edit', { url: 'event/Index.vue', id: item.id })"
        >
          <BaseIcon class="ic-16 grey" icon="edit" /> Редактировать
        </button>
        <button class="btn transparent-grey" @click="isDelete = !isDelete">
          <BaseIcon class="ic-16 grey" icon="delete" /> Удалить
        </button>
      </template>
    </BaseDotMenu>
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import { defineEmits, defineProps, ref, toRefs, computed } from 'vue'
import { BaseLoad, BaseIcon, BaseDotMenu } from '@/components'
import { $busEmit, copyTextToClipboard, formatDateTime } from '@/plugins'

// Emits
const emits = defineEmits(['updateList'])

// Props
const props = defineProps({
  item: {
    type: Object,
    default: () => ({})
  }
})

// Data
const { item } = toRefs(props)
const store = useStore()
const isDelete = ref(false)
const isCopied = ref(false)
const isLoad = ref(false)

// Computed
const profile = computed(() => store.getters.profile)
const isAdmin = computed(() => profile.value?.negotiation_role === 'Admin')

// Methods
async function deleteAction(del = true) {
  try {
    isLoad.value = true
    if (del) {
      await store.dispatch('events/DELETE', item.value.id)
      $busEmit('setToast', {
        type: 'green',
        icon: 'check-circle',
        message: `Мероприятие успешно удалено!`
      })
    } else {
      const payload = {
        ...item.value,
        status: 'deleted',
        action: 'update',
        event_location_id: item.value.event_location?.id
      }
      await store.dispatch('events/POST', payload)
      emits('updateList')
    }
  } finally {
    isLoad.value = false
  }
}
async function shareAction() {
  const link = `${window.location.origin}/events/single/${item.value.id}`
  isCopied.value = await copyTextToClipboard(link)
  $busEmit('setToast', {
    type: 'green',
    icon: 'check-circle',
    message: `Ссылка на мероприятие <a href="${link}">${item.value.title}</a> успешно скопированна!`
  })
}
</script>

<style lang="scss" scoped>
.item {
  border-bottom: var(--b1);

  &:hover {
    background-color: var(--grey-el);
    z-index: 3;
  }
  &:first-child {
    border-radius: var(--br) var(--br) 0 0;
  }
  &:last-child {
    border: 0;
    border-radius: 0 0 var(--br) var(--br);
  }
  &.single {
    border: 0;
    border-radius: var(--br);
  }

  &-delete {
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background-color: var(--rgba-red-08);
    backdrop-filter: blur(5px);
  }

  &-title {
    color: var(--text);
    font-weight: bold;
  }

  // &-count,
  // &-tag {
  //   color: var(--grey-ed);
  // }

  &-sub {
    span,
    a {
      display: inline-flex;
      vertical-align: middle;
      margin-right: 10px;
    }
  }

  &-menu {
    position: relative;
    z-index: 0;

    &:hover {
      z-index: 2;
    }

    &:hover &-btn {
      background-color: var(--grey);
    }

    &-body {
      display: none;
      position: absolute;
      right: 0;
      top: 100%;
      box-shadow: var(--box-shadow);
    }

    &:hover &-body {
      display: grid;
    }
  }
}
</style>
