<template>
  <div class="events flex fd-c ggap-10 pos-r">
    <!-- Toolbar -->
    <div class="events__toolbar box">
      <!-- Head -->
      <TabSwipeLinks
        v-if="isAdmin"
        class="events__toolbar-head ggap-30 pl-20 pr-20 ai-c"
        :items="tabs"
        v-model="currentTab"
      >
        <template #item="{ item }">
          <div class="flex ai-c jc-c ggap-10 h-100">
            {{ item.text }}
            <small v-if="item.count" class="marker" :class="currentTab === item.value ? 'primary' : 'grey'">
              {{ item.count }}
            </small>
          </div>
        </template>
      </TabSwipeLinks>

      <!-- Search (потом запихнуть в отдельный компонент) -->
      <div class="events__toolbar-filter pt-10 pb-10 pl-20 pr-20 grid gtc-1-auto">
        <div class="events__toolbar-search pos-r z0">
          <BaseIcon class="search ic-20 grey" icon="search" />
          <button v-if="searchText" class="btn outline sm rounded cube-24" @click.prevent="searchText = ''">
            <BaseIcon class="ic-16 grey" icon="close" />
          </button>
          <input
            type="text"
            class="w-100 form-item__input pl-40 pr-40"
            placeholder="Найти мероприятие"
            v-model="searchText"
          />
        </div>
      </div>
    </div>

    <div class="pos-r z1">
      <BaseLoad v-if="isLoad" class="grid jc-c z3 bg white box mh-100" />
      <template v-else>
        <template v-if="rows && rows.length">
          <div class="events__list box">
            <Item
              v-for="item of rows"
              :key="item.id"
              :item="item"
              :class="rows && rows.length === 1 ? 'single' : ''"
              @updateList="getRows"
            />
          </div>
          <BaseLoad v-if="isLoadMore" class="rel sm" />
        </template>
        <div v-else class="p-20 ta-center box">
          <h2 class="title mb-5">Упс, ничего не найдено!</h2>
          <small class="t-grey-ed">Попробуйте искать иначе либо в другом месте.</small>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
import { debounce } from 'lodash'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { ref, computed, watch, onMounted } from 'vue'
import { BaseLoad, BaseIcon, TabSwipeLinks } from '@/components'
import Item from './components/Item.vue'
import { socketConnect } from '@/plugins'

// Data
const store = useStore()
const route = useRoute()
const isLoad = ref(false)
const isLoadMore = ref(false)
const searchText = ref('')
const currentTab = ref('all')
const tabs = [
  {
    text: 'Активные мероприятия',
    value: 'all',
    link: '/events',
    append: false,
    count: ''
  },
  {
    text: 'Архив мероприятий',
    value: 'archive',
    link: '/events/archive',
    append: false,
    count: ''
  }
]

// Computed
const profile = computed(() => store.getters.profile)
const isAdmin = computed(() => profile.value?.negotiation_role === 'Admin')
const rows = computed(() => store.getters['events/rows'])
const pages = computed(() => store.getters['events/pages'])

// Watch
watch(pages, async (val) => {
  const el = document.querySelector('.default-main')
  if (el.scrollTop > el.scrollHeight - 2000 && val?.last_page > 1) {
    await getNextRows()
  }
})
watch(route, async () => {
  await getRows()
})
watch(searchText, debounce(getRows, 500))

// Created
getRows()
wsConnect()

// Mounted
onMounted(() => {
  const el = document.querySelector('.default-main')
  el.addEventListener('scroll', async function () {
    if (el.scrollTop > el.scrollHeight - 2000) await getNextRows()
  })
})

// Methods
async function getRows() {
  try {
    isLoad.value = true
    const params = {
      status: []
    }
    if (searchText.value) params.s = searchText.value
    if (route.fullPath === '/events/archive') {
      params.status.push('finished')
      params.status.push('deleted')
    } else {
      params.status.push('active')
    }
    await store.dispatch('events/GET_ROWS', params)
  } catch (error) {
    console.log(error)
  } finally {
    isLoad.value = false
  }
}
async function getNextRows() {
  if (pages.value?.current_page < pages.value?.last_page && !isLoadMore.value) {
    try {
      isLoadMore.value = true
      const params = {
        status: [],
        page: pages.value.current_page + 1
      }
      if (searchText.value) params.s = searchText.value
      if (route.fullPath === '/events/archive') {
        params.status.push('finished')
        params.status.push('deleted')
      } else {
        params.status.push('active')
      }
      await store.dispatch('events/GET_ROWS', params)
    } catch (error) {
      console.log(error)
    } finally {
      isLoadMore.value = false
    }
  }
}

// Methods:websocket
function wsConnect() {
  const name = `community`
  socketConnect.channel(name).listen('.CommunityCreatedEvent', () => {
    getRows()
  })
}
</script>

<style lang="scss" scoped>
.events {
  &__toolbar {
    position: sticky;
    top: 0;
    z-index: 100;
    box-shadow: 0 -30px 0 0 var(--bg);

    &-head {
      height: 50px;
    }

    &-search {
      .search,
      .btn {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
      .search {
        left: 10px;
        pointer-events: none;
      }
      .btn {
        right: 5px;
      }
    }
  }
}
</style>
